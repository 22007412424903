<template>
  <div class="embed-video-detail">
    <div>
      <div class="video-title">
        {{ this.video ? this.video.full.title : '' }}
      </div>
<!--      <div class="video-detail">-->
<!--        <div class="video-views">-->
<!--          {{this.video.view_count}} views-->
<!--        </div>-->
<!--        <span class="dot"></span>-->
<!--        <div class="video-published-date">-->
<!--          Published on {{this.video.published_on}}-->
<!--        </div>-->
<!--      </div>-->
      <div class="line"></div>
      <div class="video-description">
<!--        <div class="logo">-->
<!--          <img v-if="this.video" :src="this.video.owner_photo" height="100" width="100">-->
<!--          <span v-if="this.video">{{this.video.owner_name}}</span>-->
<!--        </div>-->
        <div class="des" >

          <div>
            <span v-if="showMore"> {{this.video ? this.video.full.description : '' }} </span>
            <span v-if="!showMore"> {{this.video ? ( this.video.full.description.length > 260 ? this.video.full.description.substring(0,240)  + '...' : this.video.full.description ): '' }} </span>
          </div>

          <div
                  @click="showMore = false"
                  class="text-uppercase cursor-pointer show-more-less"
                  v-if="showMore && ( this.video && this.video.full.description.length > 260 )"
          >Show less</div>
          <div
                  @click="showMore = true"
                  class="text-uppercase cursor-pointer show-more-less"
                  v-if="!showMore  && ( this.video && this.video.full.description.length > 260 )"
          >Show more</div>
<!--          <span>Show More</span>-->
        </div>

      </div>
      <div class="line"></div>
      <!--    <hr>-->
    </div>

  </div>
</template>
<script>

export default {
  name: 'EmbedVideoDetail',
  props: ["video"],
  data: function () {
    return {
      showMore:false
    };
  },

  components: {},

  created: function () {


  },

  mounted: function () {

  },

  computed: {},

  methods: {},

};
</script>
<style lang="less">

.embed-video-detail {
  margin: 35px 0px;


  .show-more-less{
    font-size: 13px;
    font-weight: 500;
    color: #637C8E;
    margin-top: 5px;
  }

  .video-title {
    font-size: 25px;
    font-weight: 600;
    color: #21455e;
    line-height: 30px;
  }

  .video-detail {
    display: inline-flex;
    margin-top: 15px;
    font-size: 16px;
    color: #2e465eb0;
  }

  .dot {
    height: 6px;
    width: 6px;
    background-color: #2e465eb0;
    border-radius: 50%;
    display: inline-block;
    margin: 5px 15px;
  }

  .line {
    border-bottom: 1.5px solid #33abd847;
    margin: 20px 0;
  }

  .video-description {
    display: flex;

    .des {
      /*margin-left: 35px;*/
      font-size: 19px;
      line-height: 26px;
      color: #21455e;
      span{
        font-size: 15px;
        color: #6f829f;
      }
    }
  }

  .logo {
    text-align: center;

    img {
      border-radius: 50%;
    }

    span {
      line-height: 30px;
      text-align: center;
      color: #214674;
      font-weight: 600;
      display: block;
    }

  }
}
@media (max-width: 800px) {
  .embed-video-detail .video-description .des {
    font-size: 15px;
    line-height: 25px;
  }
  .embed-video-detail .logo img{
    height: 70px;
    width: 70px;
  }
  .embed-video-detail .logo span {
    line-height: 20px;
  }
}
</style>